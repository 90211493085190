/** @typedef {'separation-divorce' | 'child custody' | 'mediation' | 'liability' | 'construction' | 'insurance' | 'real estate'} Expertise */

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

export const EXPERTISE = {
  SEPARATION_DIVORCE: 'separation-divorce',
  CHILD_CUSTODY: 'child-custody',
  MEDIATION: 'mediation',
  LIABILITY: 'liability',
  CONSTRUCTION: 'construction',
  INSURANCE: 'insurance',
  REAL_ESTATE: 'real-estate',
};

/**
 * @param {Expertise} expertise
 */
export const generateBreadcrumb = (expertise) => (
  <p className="fil">
    <Link to="/en/home">home</Link>
    &nbsp;›&nbsp;
    <Link to="/en/expertise">expertise</Link>
    &nbsp;›&nbsp;
    <span className="fil">{expertise}</span>
  </p>
);

const generateMenu = (selectedOption) => {
  const options = [
    [EXPERTISE.SEPARATION_DIVORCE, 'Separation-Divorce'],
    [EXPERTISE.CHILD_CUSTODY, 'Child Custody'],
    [EXPERTISE.MEDIATION, 'Mediation'],
    [EXPERTISE.LIABILITY, 'Liability'],
    [EXPERTISE.CONSTRUCTION, 'Construction'],
    [EXPERTISE.INSURANCE, 'Insurance'],
    [EXPERTISE.REAL_ESTATE, 'Real Estate'],
  ];

  return options.map(([option, name], i) => {
    if (selectedOption === option) {
      return (
        <li key={option} className="activeText">
          &nbsp;›&nbsp;&nbsp;&nbsp;{name}
        </li>
      );
    } else {
      const href = `/en/expertise/${option}`;
      const lastClass = i === options.length - 1 ? 'lastChild' : '';

      return (
        <Link key={option} to={href}>
          <li className={lastClass}>
            &nbsp;›&nbsp;&nbsp;&nbsp;
            <span>{name}</span>
          </li>
        </Link>
      );
    }
  });
};

const ExpertiseContainer = ({ currentOption, children }) => {
  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Expertise</h1>
      </div>

      <div id="info">
        {/* menu navigation gauche */}

        <div id="navCompetencesPlus">
          <ul>
            <li className="firstChild">Expertise</li>
            {generateMenu(currentOption)}
          </ul>
        </div>

        <div id="texteCompPlus">{children}</div>
      </div>
    </>
  );
};

ExpertiseContainer.propTypes = {
  children: PropTypes.node.isRequired,
  currentOption: PropTypes.oneOf(Object.values(EXPERTISE)).isRequired,
};

export default ExpertiseContainer;
