import React from 'react';

import { PAGES } from '../../../utils/utils';
import RealEstate from '../../../components/en/expertise/real-estate';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

const RealEstatePage = () => (
  <Layout lang="en" page={PAGES.COMPETENCES_IMMOBILIER}>
    <SEO title="Real Estate" lang="en" />
    <RealEstate />
  </Layout>
);

export default RealEstatePage;
