import React from 'react';

import ExpertiseContainer, { EXPERTISE, generateBreadcrumb } from './container';

const RealEstate = () => {
  return (
    <ExpertiseContainer currentOption={EXPERTISE.REAL_ESTATE}>
      <h1>Real Estate</h1>

      {generateBreadcrumb('real estate')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="immobilier"
          src="/img/competences_sprite.jpg"
          alt="Real Estate"
        />
      </div>

      <p>
        Disagreements may arise after the acquisition of a home, be it requests
        for cancellation of the sale or reduction of the sale price,
        neighborhood annoyances, land limits and common ownership issues, etc.
      </p>
      <p>
        In all these cases, our services will prove to be useful to defend your
        rights in the most efficient way.
      </p>
    </ExpertiseContainer>
  );
};

RealEstate.propTypes = {};

export default RealEstate;
